import { toGeoJSON } from '@mapbox/polyline'
/**
 * convierte la ruta a formato geoJson
 * útil para utilizar como un source para layers en mapbox
 */
const getGeoJson = ({ legs }) => {
  const geoJson = {
    type: 'FeatureCollection',
    features: legs.map(({ mode, legGeometry: { points } }) => ({
      type: 'Feature',
      geometry: toGeoJSON(points),
      properties: {
        mode
      }
    }))
  }

  legs.forEach((l) => {
    const point = {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [l.from.lon, l.from.lat]
      },
      properties: {
        mode: `${l.mode}`
      }
    }
    geoJson.features.push(point)
  })
  return geoJson
}

export default getGeoJson
