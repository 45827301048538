import { Modes } from './constants'
import apiServices from './apiServices'

const getId = () => `${Date.now()}_${Math.floor(Math.random() * 100000)}`

const Planner = () => {
  let config = {
    mode: [
      Modes.TRANSIT, Modes.WALK, Modes.SUBWAY,
      Modes.BUS, Modes.RAIL
    ],
    maxWalkDistance: 500
  }

  return {
    setConfig: ({ mode, maxWalkDistance }) => {
      config = { ...config, mode, maxWalkDistance }
    },
    getConfig: () => ({ ...config, mode: [...config.mode] }),
    /**
     * from, to properties must contain lat, lng
     */
    getRoutes: async (from, to, requestId) => {
      const { plan, ...others } = await apiServices
        .getPlan({ from, to, ...config })
        .then((response) => response.json())
      return {
        requestId,
        plan: {
          ...plan,
          itineraries: plan.itineraries.map((route) => ({
            ...route,
            id: getId()
          }))
        },
        ...others
      }
    }
  }
}

export default Planner
