import fetch from 'isomorphic-fetch'

const serverOTP = 'http://otp.eastus2.cloudapp.azure.com'
const serverOBA = 'https://apitransporte.buenosaires.gob.ar'
const clientId = '4e9305db6bcb451293f0f2e992b198f6'
const clientSecret = 'ef1b9BcF2b434A39930Bcd943a8AbE0F'
const authentication = `?client_id=${clientId}&client_secret=${clientSecret}`

const getPlan = ({
  from: { lat: fromLat, lng: fromLng },
  to: { lat: toLat, lng: toLng },
  mode = ['TRANSIT', 'WALK'],
  maxWalkDistance = 500
// eslint-disable-next-line max-len
}) => fetch(`${serverOTP}/otp/routers/default/plan?fromPlace=${fromLat},${fromLng}&toPlace=${toLat},${toLng}&mode=${mode.join(',')}&maxWalkDistance=${maxWalkDistance}&arriveBy=false`)

// eslint-disable-next-line max-len
const getBusArrivalsDeparturesByStop = (stop) => fetch(`${serverOBA}/colectivos/oba/arrivals-and-departures-for-stop/82_${stop}${authentication}`)

export default { getPlan, getBusArrivalsDeparturesByStop }
