import { Modes } from './constants'
import apiServices from './apiServices'

const getTimes = ({ data: { entry: { arrivalsAndDepartures } } }) => arrivalsAndDepartures
  .map(({
    predicted,
    predictedArrivalTime, scheduledArrivalTime,
    predictedDepartureTime, scheduledDepartureTime,
    routeShortName, routeLongName, routeId, stopId, vehicleId, tripHeadsign
  }) => ({
    predicted,
    arrivalTime: predicted ? predictedArrivalTime : scheduledArrivalTime,
    departureTime: predicted ? predictedDepartureTime : scheduledDepartureTime,
    routeLongName,
    routeId,
    routeShortName,
    stopId,
    vehicleId,
    tripHeadsign
  }))

// returns an array with information about each bus that passes through said stop.
export const getArrivalsDepartures = async ({ mode, stop }) => {
  let arrivalsDepartures
  switch (mode) {
    case Modes.BUS:
      arrivalsDepartures = await apiServices
        .getBusArrivalsDeparturesByStop(stop)
        .then((response) => response.json())
      break
    default:
      throw new Error('This mode is not supported')
  }
  // filter only the necessary information
  return getTimes(arrivalsDepartures)
}

// if the stop has predictive service, the status of the filtered line is consulted according to the interval
export async function* arrivalsDeparturesWithPredictive({
  mode, stop, filter, interval = 5000
}) {
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms))
  const getFiltered = async () => (await getArrivalsDepartures({ mode, stop }))
    .filter((arrivalDeparture) => !filter || filter(arrivalDeparture))
  let arrivalsDepartures = await getFiltered()
  const hasPredicted = arrivalsDepartures.some((time) => time.predicted)
  // This loop will remain true to be can execute the .next() method of the generator function and so update the information
  while (hasPredicted) {
    yield arrivalsDepartures
    arrivalsDepartures = await delay(interval).then(getFiltered)
  }
  return arrivalsDepartures
}
